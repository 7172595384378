import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <h1>Privacy Policy</h1>
  
    <p>
      We built the app, Runder, as a free app.
      Runder is intended to be used as is.
    </p>
    <p>
      This page is used to inform visitors regarding the policies with the collection, use, and disclosure of personal information if anyone decided to use Runder.
    </p>
    <p>
      If you choose to use Runder, then you agree to the collection and use of information in relation to this policy.
      The personal information that we collect is used for providing and improving the service, and social contribution.
    </p>
    <p>
      The terms used in this Privacy Policy have the same meanings as in Terms and Conditions, which are accessible at Runder unless otherwise defined in this Privacy Policy.
    </p>
    <p>
      <strong>Information Collection and Use</strong>
    </p>
    <p>
      For a better experience, while using Runder, we may require you to provide us with certain personally identifiable information, including but not limited to name, email address.
      You can delete your personal information by deleting your account of Runder.
    </p>
    <div>
      <p>
        Runder does use third-party services that may collect information used to identify you.
      </p>
      <p>
        Link to the privacy policy of third-party service providers used by Runder.
      </p>
      <ul>
        <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
      </ul>
    </div>
    <p>
      Runder also collects location data to enable running tracking even background.
    </p>
    <p>
      We may use and publish statistical information for improving Runder or social contribution.
      These information do not include your name and emali address but include your city, activity result, and status of use Runder.
      We may also provide any research institutes with these statistical information.
    </p>
    <p>
      <strong>Log Data</strong>
    </p>
    <p>
      We want to inform you that whenever you use Runder, we collect data and information (through third-party products) on your phone called Log Data.
      This Log Data may include information such as your device Internet Protocol ("IP") address, device name, operating system version, the configuration of Runder when utilizing, the time and date of your use of Runder, and other statistics.
    </p>
    <p>
      <strong>Service Providers</strong>
    </p>
    <p>
      We may employ third-party companies and individuals due to the following reasons:
    </p>
    <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>
      We want to inform users of the app that these third parties have access to their personal information.
      The reason is to perform the tasks assigned to them on our behalf.
      However, they are obligated not to disclose or use the information for any other purpose.
    </p>
    <p>
      <strong>Security</strong>
    </p>
    <p>
      We value your trust in providing us your personal information, thus we are striving to use commercially acceptable means of protecting it.
      But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
    </p>
    <p>
      <strong>Links to Other Sites</strong>
    </p>
    <p>
      Runder may contain links to other websites.
      If you click on a third-party link, you will be directed to that site.
      Note that these external sites are not operated by us.
      Therefore, we strongly advise you to review the Privacy Policy of these websites.
      We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
    </p>
    <p>
      <strong>Changes to This Privacy Policy</strong>
    </p>
    <p>
      We may update our Privacy Policy from time to time.
      Thus, you are advised to review this page periodically for any changes.
    </p>
    <p>This policy is effective as of 2022-02-21.</p>
    <p>
      <strong>Contact Us</strong>
    </p>
    <p>
      If you have any questions or suggestions about the Privacy Policy, do not hesitate to contact us at contact@shundroid.com.
    </p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
